import { Link } from 'gatsby';
import React from 'react';

const FlowLayout: React.FC = ({ children }) => {
  return (
    <div className="font-sans antialiased">
      <div className="flex min-h-screen relative">
        <div className="absolute ml-4 md:ml-9 mt-8">
          <Link
            className="w-9 h-9 rounded-full border border-input-grey flex items-center justify-center"
            to="/get-started"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="10"
              fill="none"
              viewBox="0 0 11 10"
            >
              <path
                fill="#0A2533"
                d="M3.535 5.374h7.172v-1.67H3.535l2.506-2.507-1.18-1.18L.927 3.947a.835.835 0 000 1.181L4.86 9.061 6.041 7.88 3.535 5.374z"
              ></path>
            </svg>
          </Link>
        </div>

        <div className="flex-grow flex items-center justify-center">
          <div className="md:w-120 max-w-full px-6 py-24 md:py-8">
            {children}
          </div>
        </div>
        <div
          className="lg:w-96 bg-cover bg-center bg-no-repeat hidden md:block md:w-64"
          style={{ backgroundImage: 'url(/images/signup-bg.png)' }}
        ></div>
      </div>
    </div>
  );
};

export default FlowLayout;
