import React from 'react';

interface CheckboxProps {
  checked?: boolean;
  className?: string;
  onClick: () => void;
}

const Checkbox: React.FC<CheckboxProps> = ({ checked, className, onClick }) => {
  return (
    <button
      type="button"
      className={`flex flex-shrink-0 items-center transition justify-center w-5 h-5 border focus:ring-0 focus:outline-none ${
        checked ? 'border-primary bg-primary' : 'border-input-grey bg-white'
      } ${className}`}
      onClick={onClick}
    >
      {checked && (
        <svg
          className="w-3 h-auto"
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="11"
          fill="none"
          viewBox="0 0 14 11"
        >
          <path
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M13 1.375l-8.25 8.25L1 5.875"
          ></path>
        </svg>
      )}
    </button>
  );
};

export default Checkbox;
