import { graphql, Link } from 'gatsby';
import React, { useState } from 'react';
import useFetch from 'use-http';
import * as Yup from 'yup';
import Checkbox from 'components/atoms/Checkbox';
import FlowLayout from 'components/base/FlowLayout';
import useHubspot from 'hooks/use-hubspot';

export interface SignUpFormModel {
  firstname: string;
  lastname: string;
  email: string;
  jobtitle: string;
  company: string;
  mobilephone: string;
  opted_in_: boolean;
}

const initialValues: SignUpFormModel = {
  firstname: '',
  lastname: '',
  email: '',
  jobtitle: '',
  company: '',
  mobilephone: '',
  opted_in_: false,
};

const phoneRegex = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = Yup.object().shape({
  firstname: Yup.string().required('This field is required'),
  lastname: Yup.string().required('This field is required'),
  email: Yup.string()
    .email('Please provide valid email')
    .required('This field is required'),
  jobtitle: Yup.string().required('This field is required'),
  company: Yup.string().required('This field is required'),
  mobilephone: Yup.string()
    .required('This field is required')
    .matches(phoneRegex, 'Phone number is not valid'),
});

const SignUpPageContent = ({
  main,
  successMessage,
  forceSuccess, // This is only for preview mode
}) => {
  const { post, error, response } = useFetch(
    'https://api.hsforms.com/submissions/v3/integration/submit/7551458/bca4c4b8-d849-40f7-b850-07bccb27c24d',
  );

  const gtmVariables = {
    event: 'Lead Form Submission Succeeded',
    'gtm.elementClasses': 'lead-form',
    'gtm.elementId': 'lead-form-speak-with-us',
  };

  const {
    values: {
      firstname,
      lastname,
      email,
      jobtitle,
      company,
      mobilephone,
      opted_in_,
    },
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    touched,
    errors,
  } = useHubspot({ initialValues, schema, post, gtmVariables });

  const ErrorMessage = ({ name }) => {
    return errors[name] && touched[name] ? (
      <div className="text-sm text-red-500 -mt-8 mb-8">{errors[name]}</div>
    ) : null;
  };

  return (
    <FlowLayout>
      {response?.ok || forceSuccess ? (
        <div>
          <h2 className="mb-3.5 font-semibold text-4xl tracking-tightest">
            {successMessage?.title}
          </h2>
          <p className="mb-6 text-lg">{successMessage?.body}</p>
          <Link to="/" className="u-cta-link">
            Take me home
          </Link>
        </div>
      ) : (
        <div className="lg:pt-32 SignUp">
          <h2 className="mb-3.5 font-semibold text-4xl tracking-tightest">
            {main?.title}
          </h2>
          <p className="mb-6 text-lg">{main?.body}</p>
          <form
            className="mt-12"
            action="/"
            method="post"
            onSubmit={handleSubmit}
          >
            <div className="md:flex md:space-x-5">
              <div className="flex-1">
                <label className="u-label" htmlFor="firstname">
                  First Name
                </label>
                <input
                  className="u-input"
                  type="text"
                  name="firstname"
                  id="firstname"
                  value={firstname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage name="firstname" />
              </div>
              <div className="flex-1">
                <label className="u-label" htmlFor="lastname">
                  Last Name
                </label>
                <input
                  className="u-input"
                  type="text"
                  name="lastname"
                  id="lastname"
                  value={lastname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage name="lastname" />
              </div>
            </div>

            <div className="md:flex md:space-x-5">
              <div className="flex-1">
                <label className="u-label" htmlFor="email">
                  Email Address
                </label>
                <input
                  className="u-input"
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage name="email" />
              </div>
              <div className="flex-1">
                <label className="u-label" htmlFor="jobtitle">
                  Job Title
                </label>
                <input
                  className="u-input"
                  type="text"
                  name="jobtitle"
                  id="jobtitle"
                  value={jobtitle}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage name="jobtitle" />
              </div>
            </div>

            <div className="md:flex md:space-x-5">
              <div className="flex-1">
                <label className="u-label" htmlFor="company">
                  Company
                </label>
                <input
                  className="u-input"
                  type="text"
                  name="company"
                  id="company"
                  value={company}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage name="company" />
              </div>
              <div className="flex-1">
                <label className="u-label" htmlFor="mobilephone">
                  Phone
                </label>
                <input
                  className="u-input"
                  type="text"
                  name="mobilephone"
                  id="mobilephone"
                  value={mobilephone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage name="mobilephone" />
              </div>
            </div>

            <div>
              <label className="flex">
                <Checkbox
                  checked={opted_in_}
                  onClick={() => setFieldValue('opted_in_', !opted_in_)}
                />
                <div className="ml-6 text-mid-grey opacity-60 text-sm">
                  Please email me about Skupos products and services. You can
                  always opt-out later. See our{' '}
                  <Link
                    to="/privacy"
                    className="font-semibold underline text-paragraph hover:text-primary focus:text-primary"
                  >
                    Privacy Policy
                  </Link>{' '}
                  for more details.
                </div>
              </label>
            </div>

            <div className="mt-14 space-y-6 md:space-x-7">
              <button type="submit" className="u-btn u-btn--green">
                Submit
              </button>
              <Link className="u-btn u-btn--outline" to="/get-started">
                Cancel
              </Link>
            </div>

            {error && (
              <div className="text-red-500 mt-4">{response.data?.message}</div>
            )}
          </form>
        </div>
      )}
    </FlowLayout>
  );
};

export const SignUpPreview = ({ entry }) => {
  const [forceSuccess, setForceSuccess] = useState(false);

  return (
    <div>
      <div
        className="text-white bg-primary px-2.5 py-1.5 my-2 rounded-full inline-block text-xs cursor-pointer"
        onClick={() => {
          setForceSuccess(!forceSuccess);
        }}
      >
        {forceSuccess ? 'Show Form' : 'Show Sucess Message'}
      </div>
      <hr />
      <SignUpPageContent
        {...entry.get('data').toJS()}
        forceSuccess={forceSuccess}
      />
      ;
    </div>
  );
};

const SignUpPage = ({
  data: {
    markdownRemark: { frontmatter },
  },
}) => {
  return (
    <>
      <SignUpPageContent {...frontmatter} />
    </>
  );
};

export const pageQuery = graphql`
  query SignUpPageQuery {
    markdownRemark(fileAbsolutePath: { regex: "/pages/sign-up/" }) {
      frontmatter {
        main {
          title
          body
        }
        cta {
          label
          url
        }
        successMessage {
          title
          body
        }
      }
    }
  }
`;

export default SignUpPage;
